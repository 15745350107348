import { Counter, Report } from '@geovelo-frontends/commons';
import { FileCopyOutlined, ForumOutlined } from '@mui/icons-material';
import {
  Badge,
  Box,
  Checkbox,
  Collapse,
  IconButton,
  Table as MuiTable,
  Skeleton,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TablePaginationProps,
  TableProps,
  TableRow,
  TableSortLabel,
  Tooltip,
} from '@mui/material';
import { Moment } from 'moment';
import { ChangeEvent, Fragment, MouseEvent as ReactMouseEvent, ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import DownloadIcon from './icons/download';

export interface ITableRef {
  reorder: () => void;
  reloadTablePage: (removed?: boolean) => void;
  reloadTableReport?: (report: Report) => void;
  reloadTableCounter?: (counter: Counter) => void;
}

export type THeader = { label?: ReactNode; sortable?: boolean; width?: number };

export type TValue = string | number | Moment | boolean | null;

export type TCells<TCellKey extends string> = {
  [key in TCellKey]?: {
    value: TValue;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    format?: (value: any) => ReactNode;
  };
};

export type TRow<TKey extends string | number, TCellKey extends string> = {
  key: TKey;
  cells: TCells<TCellKey>;
  details?: ReactNode;
  inkBarColor?: string;
  messagesCount?: number;
};

export type TOrder = 'asc' | 'desc';

export type TRowsPerPage = 5 | 10 | 25 | 50 | 100;

const defaultRowsPerPageOptions: TRowsPerPage[] = [5, 10, 25];

interface IProps<TKey extends string | number, TCellKey extends string> {
  whiteBackgroundColor?: boolean;
  count?: number;
  detailedKey?: TKey | null;
  downloading?: { [key in TKey]?: boolean };
  hasBorder?: boolean;
  hasInkBars?: boolean;
  hasDetails?: boolean;
  headers: { [key in TCellKey]?: THeader };
  keys: TCellKey[];
  onChatOpen?: (key: TKey) => void;
  onClick?: (key: TKey) => void;
  onCopy?: (key: TKey) => void;
  onDownload?: (key: TKey) => void;
  onPageChange?: (
    event: ReactMouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number,
  ) => void;
  onRowsPerPageChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onSelectAllKeys?: () => void;
  onSortRequest?: (key: TCellKey) => void;
  onToggleKey?: (key: TKey) => void;
  onUnselectAllKeys?: () => void;
  order?: TOrder;
  orderBy?: TCellKey;
  page?: number;
  rows?: TRow<TKey, TCellKey>[];
  rowsPerPage?: number;
  rowsPerPageOptions?: TRowsPerPage[];
  size?: 'small' | 'medium';
  selectable?: boolean;
  selectedKey?: TKey;
  selectedKeys?: TKey[];
  tablePaginationProps?: Partial<TablePaginationProps>;
  tableProps?: TableProps;
  title: string;
}

function Table<TKey extends string | number, TCellKey extends string>({
  whiteBackgroundColor,
  downloading,
  title,
  tableProps,
  tablePaginationProps,
  keys,
  hasBorder,
  hasInkBars,
  hasDetails,
  selectable,
  selectedKeys: _selectedKeys,
  size,
  headers,
  count,
  rows,
  orderBy,
  order,
  page,
  rowsPerPage,
  rowsPerPageOptions,
  selectedKey,
  detailedKey,
  onSortRequest,
  onPageChange,
  onRowsPerPageChange,
  onToggleKey,
  onSelectAllKeys,
  onUnselectAllKeys,
  onChatOpen,
  onCopy,
  onDownload,
  onClick,
}: IProps<TKey, TCellKey>): JSX.Element {
  const { t } = useTranslation();

  const loadingItems = [1, 2, 3];

  function createSortHandler(property: TCellKey) {
    return () => {
      if (onSortRequest) onSortRequest(property);
    };
  }

  const selectedKeys = _selectedKeys || [];
  const hasActions = Boolean(onDownload || onChatOpen || onCopy);

  return (
    <Wrapper className={whiteBackgroundColor ? 'white' : ''}>
      <TableWrapper className={hasBorder ? 'border' : ''}>
        <MuiTable stickyHeader aria-label={title} {...tableProps}>
          <TableHead>
            <TableRow>
              {hasInkBars && <StyledInkBar color="#f6f7fb" padding="none" />}
              {rows && selectable && (
                <StyledTableCell padding="checkbox">
                  <Checkbox
                    checked={selectedKeys.length > 0}
                    color="primary"
                    indeterminate={selectedKeys.length > 0 && selectedKeys.length < rows.length}
                    onClick={() => {
                      if (selectedKeys.length === 0) {
                        if (onSelectAllKeys) onSelectAllKeys();
                      } else if (onUnselectAllKeys) onUnselectAllKeys();
                    }}
                    size="small"
                  />
                </StyledTableCell>
              )}
              {keys.map((key) => {
                const header = headers[key];

                return (
                  <StyledTableCell key={key} sx={{ minWidth: header?.width, width: header?.width }}>
                    {header?.sortable && orderBy && order && rows ? (
                      <TableSortLabel
                        active={orderBy === key}
                        direction={orderBy === key ? order : 'asc'}
                        onClick={createSortHandler(key)}
                      >
                        {header.label}
                        {orderBy === key ? (
                          <StyledSortAriaLabel>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </StyledSortAriaLabel>
                        ) : null}
                      </TableSortLabel>
                    ) : (
                      header?.label
                    )}
                  </StyledTableCell>
                );
              })}
              {hasActions && <StyledTableCell padding="checkbox" />}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              ? rows.map(({ key: rowKey, cells, details, inkBarColor, messagesCount }) => {
                  return (
                    <Fragment key={rowKey}>
                      <StyledTableRow
                        className={
                          selectedKeys.indexOf(rowKey) > -1
                            ? 'selected'
                            : rowKey === selectedKey
                              ? 'active'
                              : ''
                        }
                        clickable={onClick ? 'true' : 'false'}
                        onClick={() => onClick?.(rowKey)}
                      >
                        {hasInkBars && (
                          <StyledInkBar color={inkBarColor || 'black'} padding="none" />
                        )}
                        {selectable && (
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={selectedKeys.indexOf(rowKey) > -1}
                              color="primary"
                              onClick={() => (onToggleKey ? onToggleKey(rowKey) : undefined)}
                              size="small"
                            />
                          </TableCell>
                        )}
                        {keys.map((key) => {
                          const cell = cells[key];

                          return (
                            <TableCell
                              key={key}
                              size={size || 'medium'}
                              sx={{ minWidth: headers[key]?.width, width: headers[key]?.width }}
                            >
                              <>{cell?.format ? cell.format(cell.value) : cell?.value}</>
                            </TableCell>
                          );
                        })}
                        {hasActions && (
                          <TableCell padding="checkbox">
                            <Box
                              alignItems="center"
                              display="flex"
                              flexShrink={0}
                              gap={1}
                              padding={0.5}
                            >
                              {onChatOpen && (
                                <Tooltip
                                  placement="left"
                                  title={<Trans i18nKey="commons.actions.open_chat" />}
                                >
                                  <IconButton onClick={() => onChatOpen(rowKey)} size="small">
                                    <Badge
                                      badgeContent={messagesCount}
                                      color="secondary"
                                      sx={{ height: 16, width: 16 }}
                                    >
                                      <ForumOutlined fontSize="small" />
                                    </Badge>
                                  </IconButton>
                                </Tooltip>
                              )}
                              {onDownload && (
                                <Tooltip
                                  placement="left"
                                  title={<Trans i18nKey="commons.actions.download" />}
                                >
                                  <span>
                                    <IconButton
                                      disabled={downloading?.[rowKey]}
                                      onClick={() => onDownload(rowKey)}
                                      size="small"
                                    >
                                      <DownloadIcon fontSize="small" />
                                    </IconButton>
                                  </span>
                                </Tooltip>
                              )}
                              {onCopy && (
                                <Tooltip
                                  placement="left"
                                  title={<Trans i18nKey="commons.actions.copy_key" />}
                                >
                                  <span>
                                    <IconButton onClick={() => onCopy(rowKey)} size="small">
                                      <FileCopyOutlined fontSize="small" />
                                    </IconButton>
                                  </span>
                                </Tooltip>
                              )}
                            </Box>
                          </TableCell>
                        )}
                      </StyledTableRow>
                      {hasDetails && (
                        <TableRow>
                          <TableCell
                            colSpan={
                              keys.length +
                              (selectable ? 1 : 0) +
                              (hasActions ? 1 : 0) +
                              (hasInkBars ? 1 : 0)
                            }
                            style={{ paddingBottom: 0, paddingTop: 0 }}
                          >
                            <Collapse in={detailedKey === rowKey}>{details}</Collapse>
                          </TableCell>
                        </TableRow>
                      )}
                    </Fragment>
                  );
                })
              : loadingItems.map((rowKey) => (
                  <TableRow key={rowKey}>
                    {hasInkBars && <StyledInkBar color="white" padding="none" />}
                    {keys.map((key) => (
                      <TableCell
                        key={key}
                        size={size || 'medium'}
                        sx={{ minWidth: headers[key]?.width, width: headers[key]?.width }}
                      >
                        {key !== 'actions' ? (
                          <Skeleton variant="text" width={30} />
                        ) : (
                          <Skeleton height={30} variant="circular" width={30} />
                        )}
                      </TableCell>
                    ))}
                    {hasActions && (
                      <TableCell padding="checkbox">
                        <Box
                          alignItems="center"
                          display="flex"
                          flexShrink={0}
                          gap={1}
                          padding={0.5}
                        >
                          {onChatOpen && <Skeleton height={30} variant="circular" width={30} />}
                          {onDownload && <Skeleton height={30} variant="circular" width={30} />}
                          {onCopy && <Skeleton height={30} variant="circular" width={30} />}
                        </Box>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
          </TableBody>
        </MuiTable>
      </TableWrapper>
      {count !== undefined &&
        page !== undefined &&
        rowsPerPage !== undefined &&
        onPageChange &&
        onRowsPerPageChange && (
          <TablePaginationWrapper>
            <TablePagination
              {...tablePaginationProps}
              component="div"
              count={count}
              getItemAriaLabel={(type) =>
                t(
                  type === 'first' || type === 'previous'
                    ? 'commons.table.prev_page'
                    : 'commons.table.next_page',
                )
              }
              labelDisplayedRows={({ from, to, count }) => (
                <Trans i18nKey="commons.table.displayed_rows" values={{ from, to, count }} />
              )}
              labelRowsPerPage={<Trans i18nKey="commons.table.rows_per_page" />}
              onPageChange={onPageChange}
              onRowsPerPageChange={onRowsPerPageChange}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={rowsPerPageOptions || defaultRowsPerPageOptions}
            />
          </TablePaginationWrapper>
        )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: hidden;

  &.white {
    background-color: #fff;
  }
`;

const TableWrapper = styled.div`
  flex-grow: 1;
  overflow-y: auto;

  &.border {
    border: 1px solid #f2f2f2;
    border-radius: 16px;
  }
`;

const StyledSortAriaLabel = styled.div`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 20px;
  width: 1px;
`;

const StyledTableCell = styled(TableCell)`
  && {
    background-color: #f6f7fb;
  }
`;

const StyledTableRow = styled(TableRow)<{ clickable: 'true' | 'false' }>`
  &:hover {
    background-color: #f2f7ff;
    cursor: ${({ clickable }) => (clickable === 'true' ? 'pointer' : 'auto')};
  }

  &.active {
    background-color: #f2f7ff;
    cursor: ${({ clickable }) => (clickable === 'true' ? 'pointer' : 'auto')};
    > * {
      color: #3e7bdf;
    }
  }

  &.selected {
    background-color: #e5f0ff;
  }

  &.active:hover {
    background-color: #e5f0ff;
  }
`;

const StyledInkBar = styled(TableCell)<{ color?: string }>`
  && {
    background-color: ${({ color }) => color};
    padding: 0 1px;
    width: 0;
  }
`;

const TablePaginationWrapper = styled.div`
  flex-shrink: 0;

  .MuiToolbar-root {
    padding-left: 16px;
  }

  .MuiInputBase-root {
    margin-right: 16px;
  }
`;

export default Table;
