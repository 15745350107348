import {
  AreaService,
  CyclabilityZone,
  CyclabilityZoneService,
  Geogroup,
  GeogroupService,
  PartnerContract,
  PartnerService,
  TAdministrativeLevel,
  TGeogroupType,
  backendAdministrativeLevels,
} from '@geovelo-frontends/commons';
import {
  Autocomplete,
  Box,
  Checkbox,
  DialogProps,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  debounce,
} from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { AppContext } from '../../../app/context';
import {
  administrativeLevels,
  contractTemplatesWithCommunity,
  contractTemplatesWithZones,
  sizeRanges,
} from '../../../components/create-partner-dialog';
import Dialog from '../../../components/dialog';
import ContractForm, { IContractFormValues } from '../../../components/form/contract-form';

type TValues = {
  title: string;
  cyclabilityZone?: CyclabilityZone | null;
  groupType: TGeogroupType | '';
  sizeRangeIndex: number;
  automaticChallenge: boolean;
} & IContractFormValues;

type TProps = Omit<DialogProps, 'onClose'> & {
  contract?: PartnerContract;
  onClose: (contract?: PartnerContract) => void;
};

function ManageContractFormDialog({
  contract: contractToUpdate,
  open,
  onClose,
  ...props
}: TProps): JSX.Element {
  const {
    partner: { current: currentPartner, contractTemplates, currentGeogroup },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [cyclabilityZoneSearch, setCyclabilityZoneSearch] = useState('');
  const [cyclabilityZoneSearchResults, setCyclabilityZoneSearchResults] =
    useState<CyclabilityZone[]>();
  const {
    isValid,
    isSubmitting,
    values,
    touched,
    errors,
    setFieldValue,
    setValues,
    handleChange,
    handleSubmit,
  } = useFormik<TValues>({
    initialValues: {
      title: '',
      startDate: '',
      endDate: '',
      contractTemplateId: 1,
      administrativeLevel: 'epci',
      cyclabilityZone: null,
      groupType: 'city',
      sizeRangeIndex: 0,
      automaticChallenge: true,
      limitAPIUsage: false,
      apiUsageLimit: 0,
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required(),
      startDate: Yup.string().required(),
      endDate: Yup.string().required(),
    }),
    onSubmit,
    enableReinitialize: true,
  });
  const locked = useRef(false);
  const fetch = useMemo(
    () =>
      debounce(async (search: string, callback: (cyclabilityZones: CyclabilityZone[]) => void) => {
        if (locked.current || !values.administrativeLevel) {
          callback([]);
          locked.current = false;
          return;
        }

        if (values.administrativeLevel === 'world') {
          callback([]);
          return;
        }

        const { zones: cyclabilityZones } = await CyclabilityZoneService.getZones({
          administrativeLevel: backendAdministrativeLevels[values.administrativeLevel],
          query: '{id, administrative_level, geo_polygon, name}',
          search,
          rowsPerPage: 5,
        });

        callback(cyclabilityZones);
      }, 700),
    [values],
  );

  useEffect(() => {
    if (open && contractTemplates) {
      const contractTemplate = contractTemplates.find(
        ({ id }) => id === contractToUpdate?.contractTemplateId,
      );
      if (contractToUpdate && contractTemplate) {
        const { id: contractTemplateId } = contractTemplate;
        const { title, startDateTime, endDateTime, apiUsageLimit } = contractToUpdate;
        setValues({
          title: title,
          startDate: startDateTime.format('yyyy-MM-DD'),
          endDate: endDateTime?.format('yyyy-MM-DD') || '',
          contractTemplateId,
          administrativeLevel: currentPartner?.administrativeLevel || 'epci',
          cyclabilityZone: null,
          groupType: currentGeogroup?.type || 'city',
          sizeRangeIndex: 0,
          automaticChallenge: true,
          limitAPIUsage: apiUsageLimit !== null,
          apiUsageLimit: apiUsageLimit || 0,
        });
      } else {
        const { id: contractTemplateId, apiUsageLimit } = contractTemplates[0];

        setValues({
          title: '',
          startDate: '',
          endDate: '',
          contractTemplateId,
          administrativeLevel: 'epci',
          cyclabilityZone: null,
          groupType: 'city',
          sizeRangeIndex: 0,
          automaticChallenge: true,
          limitAPIUsage: apiUsageLimit !== null,
          apiUsageLimit: apiUsageLimit || 0,
        });
      }
    }
  }, [open]);

  useEffect(() => {
    const contractTemplate = contractTemplates?.find(({ id }) => id === values.contractTemplateId);
    if (contractTemplate) {
      const { apiUsageLimit } = contractTemplate;

      setValues({
        ...values,
        limitAPIUsage: apiUsageLimit !== null,
        apiUsageLimit: apiUsageLimit || 0,
      });
    }
  }, [values.contractTemplateId]);

  useEffect(() => {
    let active = true;

    setCyclabilityZoneSearchResults(undefined);
    if (!cyclabilityZoneSearch) return;

    fetch(cyclabilityZoneSearch, (cyclabilityZones: CyclabilityZone[]) => {
      if (active) setCyclabilityZoneSearchResults(cyclabilityZones);
    });

    return () => {
      active = false;
    };
  }, [cyclabilityZoneSearch, fetch]);

  async function onSubmit(
    {
      title,
      startDate,
      endDate,
      limitAPIUsage,
      apiUsageLimit,
      contractTemplateId,
      cyclabilityZone,
      administrativeLevel,
      groupType,
      sizeRangeIndex,
      automaticChallenge,
    }: TValues,
    { setSubmitting, setErrors }: FormikHelpers<TValues>,
  ) {
    const contractTemplate = contractTemplates?.find(({ id }) => id === contractTemplateId);

    if (!currentPartner || !contractTemplate) return;

    setSubmitting(true);

    let props: {
      administrativeLevel?: TAdministrativeLevel;
      areaId?: number;
      geogroup?: Geogroup;
    } = {};
    if (!contractToUpdate) {
      if (contractTemplate.code && contractTemplatesWithZones.includes(contractTemplate.code)) {
        if (!cyclabilityZone) return;

        const areaId = await AreaService.createArea({ partnerTitle: title, cyclabilityZone });

        props = {
          areaId,
          administrativeLevel,
        };
      }

      if (contractTemplate.code && contractTemplatesWithCommunity.includes(contractTemplate.code)) {
        if (groupType !== 'city') {
          setErrors({ groupType: 'invalid' });
          return;
        }

        const { min, max } = sizeRanges.city[sizeRangeIndex] || {
          min: undefined,
          max: undefined,
        };

        const geogroup = await GeogroupService.addGeogroup({
          type: 'city',
          name: title,
          min,
          max,
          icon: null,
          automaticChallengeStatus: automaticChallenge ? 'automaticDistance' : 'disabled',
          publicationStatus: 'published',
        });

        props.geogroup = geogroup;
      }
    }

    if (limitAPIUsage && typeof apiUsageLimit !== 'number') {
      setErrors({ apiUsageLimit: 'invalid' });
      return;
    }

    setErrors({});

    try {
      if (contractToUpdate) {
        const updatedContract = await PartnerService.updateContract({
          partner: currentPartner,
          contractId: contractToUpdate.id,
          title,
          startDate,
          endDate,
          apiUsageLimit: limitAPIUsage ? apiUsageLimit || 0 : null,
          contractTemplateId,
        });

        onClose(updatedContract);
      } else {
        const newContract = await PartnerService.addContract({
          partner: currentPartner,
          title,
          startDate,
          endDate,
          apiUsageLimit: limitAPIUsage ? apiUsageLimit || 0 : null,
          contractTemplateId,
        });

        if (
          contractTemplate.code &&
          (contractTemplatesWithCommunity.includes(contractTemplate.code) ||
            contractTemplatesWithZones.includes(contractTemplate.code))
        ) {
          await PartnerService.editPartner(currentPartner, props);
        }

        onClose(newContract);
      }
    } catch (err) {
      enqueueSnackbar(
        t('cycling-insights.admin.manage_contracts.form_dialog.server_error', {
          context: contractToUpdate ? 'update' : undefined,
        }),
        {
          variant: 'error',
        },
      );
    }

    setSubmitting(false);
  }

  const contractTemplate = contractTemplates?.find(({ id }) => id === values.contractTemplateId);
  const zoneRequired =
    !currentPartner?.area &&
    contractTemplate?.code &&
    contractTemplatesWithZones.includes(contractTemplate.code);
  const communityRequired =
    !currentGeogroup &&
    contractTemplate?.code &&
    contractTemplatesWithCommunity.includes(contractTemplate.code);

  return (
    <Dialog
      isForm
      confirmDisabled={!isValid}
      confirmTitle={
        contractToUpdate ? (
          <Trans i18nKey="commons.actions.update" />
        ) : (
          <Trans i18nKey="commons.actions.add" />
        )
      }
      dialogTitle="contract-form-dialog"
      loading={isSubmitting}
      maxWidth="sm"
      onCancel={() => onClose()}
      onConfirm={handleSubmit}
      open={open}
      scroll="paper"
      title={
        <Trans
          i18nKey="cycling-insights.admin.manage_contracts.form_dialog.title"
          values={{ context: contractToUpdate ? 'update' : undefined }}
        />
      }
      {...props}
    >
      <Box display="flex" flexDirection="column" gap={1}>
        <TextField
          fullWidth
          disabled={isSubmitting}
          error={touched.title && Boolean(errors.title)}
          id="title"
          label={t('commons.title')}
          margin="dense"
          name="title"
          onChange={handleChange}
          size="small"
          value={values.title}
          variant="outlined"
        />
        <ContractForm<TValues>
          errors={errors}
          handleChange={handleChange}
          isSubmitting={isSubmitting}
          touched={touched}
          values={values}
        >
          {(zoneRequired || communityRequired) && (
            <Box display="flex" flexDirection="column" gap={1} marginY={2}>
              {zoneRequired && (
                <>
                  <Divider />
                  <Box display="flex" flexDirection="column">
                    <FormControl disabled={isSubmitting} margin="none">
                      <FormLabel component="legend" id="administrative-level-label">
                        <Typography variant="caption">
                          <Trans i18nKey="cycling-insights.create_partner_dialog.administrative_level" />
                        </Typography>
                      </FormLabel>
                      <Select
                        labelId="administrative-level-label"
                        name="administrativeLevel"
                        onChange={handleChange}
                        size="small"
                        value={values.administrativeLevel}
                      >
                        {administrativeLevels.map(({ key, titleKey }) => (
                          <MenuItem key={key} value={key}>
                            {t(titleKey)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Autocomplete
                      disablePortal
                      includeInputInList
                      disabled={isSubmitting}
                      forcePopupIcon={Boolean(cyclabilityZoneSearch && !values.cyclabilityZone)}
                      getOptionKey={(option) => option.id}
                      getOptionLabel={(option) => option.name}
                      loading={Boolean(cyclabilityZoneSearch) && !values.cyclabilityZone}
                      loadingText={<Trans i18nKey="commons.loading" />}
                      noOptionsText={
                        cyclabilityZoneSearch && <Trans i18nKey="commons.no_result_found" />
                      }
                      onChange={(_, value) => {
                        locked.current = true;
                        setValues({ ...values, cyclabilityZone: value || null });
                      }}
                      onInputChange={(_, value) => {
                        setTimeout(() => {
                          setCyclabilityZoneSearch(value);
                        }, 10);
                      }}
                      options={cyclabilityZoneSearchResults || []}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          error={Boolean(errors.cyclabilityZone && touched.cyclabilityZone)}
                          label={t('cycling-insights.create_partner_dialog.cyclability_zone')}
                          margin="dense"
                          size="small"
                          variant="outlined"
                        />
                      )}
                      value={values.cyclabilityZone}
                    />
                  </Box>
                </>
              )}
              {communityRequired && (
                <>
                  <Divider />
                  <FormControl disabled={isSubmitting} margin="none">
                    <FormLabel component="legend" id="group-type-label">
                      <Typography variant="caption">
                        <Trans i18nKey="cycling-insights.create_partner_dialog.group_type" />
                      </Typography>
                    </FormLabel>
                    <Select
                      labelId="administrative-level-label"
                      name="groupType"
                      onChange={(event) => {
                        if (event.target.value === '') {
                          setFieldValue('sizeRangeIndex', 0);
                        } else if (event.target.value !== 'private') {
                          setFieldValue(
                            'sizeRangeIndex',
                            Math.min(
                              values.sizeRangeIndex,
                              sizeRanges[event.target.value as TGeogroupType].length - 1,
                            ),
                          );
                        } else {
                          setFieldValue('address', null);
                        }
                        handleChange(event);
                      }}
                      size="small"
                      value={values.groupType}
                    >
                      <MenuItem value="city">
                        <Trans i18nKey="cycling-insights.create_partner_dialog.group_types.city" />
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.automaticChallenge}
                        disabled={isSubmitting}
                        name="automaticChallenge"
                        onChange={handleChange}
                        size="small"
                        value={values.automaticChallenge || false}
                      />
                    }
                    label={
                      <Trans i18nKey="cycling-insights.create_partner_dialog.automatic_challenges" />
                    }
                  />
                </>
              )}
            </Box>
          )}
        </ContractForm>
      </Box>
    </Dialog>
  );
}

export default ManageContractFormDialog;
