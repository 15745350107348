import { Box, FormControlLabel, Switch, Typography } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../app/context';
import { accidentTypes } from '../../pages/cartographic-data/models/accidentology';

import { Toggle } from './toggle';

function IncidentsToggle(): JSX.Element {
  const {
    map: { incidentsShowed },
    actions: { toggleIncidents },
  } = useContext(AppContext);
  const { t } = useTranslation();

  const allAccidentsTypesSelected = Object.values(incidentsShowed.accidents).every(Boolean);
  const allSelected =
    allAccidentsTypesSelected &&
    incidentsShowed.blackSpots &&
    incidentsShowed.reports &&
    incidentsShowed.suddenBrakings;

  return (
    <Toggle
      label={t('cycling-insights.usage.accidentology.accident_zones.all_incidents')}
      showed={allSelected}
      toggle={() =>
        toggleIncidents(
          allSelected
            ? {
                accidents: { deadly: false, hospitalized: false, injured: false },
                blackSpots: false,
                reports: false,
                suddenBrakings: false,
              }
            : {
                accidents: { deadly: true, hospitalized: true, injured: true },
                blackSpots: true,
                reports: true,
                suddenBrakings: true,
              },
        )
      }
    >
      <FormControlLabel
        control={<Switch checked={incidentsShowed.suddenBrakings} color="primary" size="small" />}
        label={
          <Box alignItems="center" component="span" display="flex" gap={1} marginRight={1}>
            <Typography component="span">
              {t('cycling-insights.usage.accidentology.sudden_brakings.label')}
            </Typography>
          </Box>
        }
        labelPlacement="start"
        onChange={(_, showed) => toggleIncidents({ ...incidentsShowed, suddenBrakings: showed })}
        onClick={(event) => event.stopPropagation()}
        onFocus={(event) => event.stopPropagation()}
        sx={{ '.MuiFormControlLabel-label': { flexGrow: 1 }, margin: 0 }}
        value={incidentsShowed.suddenBrakings}
      />
      <FormControlLabel
        control={<Switch checked={incidentsShowed.reports} color="primary" size="small" />}
        label={
          <Box alignItems="center" component="span" display="flex" gap={1} marginRight={1}>
            <Typography component="span">
              {t('cycling-insights.usage.accidentology.geovelo_reports.label')}
            </Typography>
          </Box>
        }
        labelPlacement="start"
        onChange={(_, showed) => toggleIncidents({ ...incidentsShowed, reports: showed })}
        onClick={(event) => event.stopPropagation()}
        onFocus={(event) => event.stopPropagation()}
        sx={{ '.MuiFormControlLabel-label': { flexGrow: 1 }, margin: 0 }}
        value={incidentsShowed.reports}
      />
      <FormControlLabel
        control={<Switch checked={incidentsShowed.blackSpots} color="primary" size="small" />}
        label={
          <Box alignItems="center" component="span" display="flex" gap={1} marginRight={1}>
            <Typography component="span">
              {t('cycling-insights.usage.accidentology.black_spots.label')}
            </Typography>
          </Box>
        }
        labelPlacement="start"
        onChange={(_, showed) => toggleIncidents({ ...incidentsShowed, blackSpots: showed })}
        onClick={(event) => event.stopPropagation()}
        onFocus={(event) => event.stopPropagation()}
        sx={{ '.MuiFormControlLabel-label': { flexGrow: 1 }, margin: 0 }}
        value={incidentsShowed.blackSpots}
      />
      <FormControlLabel
        control={<Switch checked={allAccidentsTypesSelected} color="primary" size="small" />}
        label={
          <Box alignItems="center" component="span" display="flex" gap={1} marginRight={1}>
            <Typography component="span">
              {t('cycling-insights.usage.accidentology.accidents.label')}
            </Typography>
          </Box>
        }
        labelPlacement="start"
        onChange={() =>
          toggleIncidents({
            ...incidentsShowed,
            accidents: allAccidentsTypesSelected
              ? { deadly: false, hospitalized: false, injured: false }
              : { deadly: true, hospitalized: true, injured: true },
          })
        }
        onClick={(event) => event.stopPropagation()}
        onFocus={(event) => event.stopPropagation()}
        sx={{ '.MuiFormControlLabel-label': { flexGrow: 1 }, margin: 0 }}
        value={allAccidentsTypesSelected}
      />
      {accidentTypes.map((type) => (
        <FormControlLabel
          control={
            <Switch checked={incidentsShowed.accidents[type]} color="primary" size="small" />
          }
          key={type}
          label={
            <Box
              alignItems="center"
              component="span"
              display="flex"
              gap={1}
              marginLeft={2}
              marginRight={1}
            >
              <Typography component="span">
                {type === 'deadly'
                  ? t('cycling-insights.usage.accidentology.accidents.label_deceased')
                  : type === 'hospitalized'
                    ? t('cycling-insights.usage.accidentology.accidents.label_hospitalized')
                    : t('cycling-insights.usage.accidentology.accidents.label_light_injuries')}
              </Typography>
            </Box>
          }
          labelPlacement="start"
          onChange={(_, showed) =>
            toggleIncidents({
              ...incidentsShowed,
              accidents: { ...incidentsShowed.accidents, [type]: showed },
            })
          }
          onClick={(event) => event.stopPropagation()}
          onFocus={(event) => event.stopPropagation()}
          sx={{ '.MuiFormControlLabel-label': { flexGrow: 1 }, margin: 0 }}
          value={incidentsShowed.accidents[type]}
        />
      ))}
    </Toggle>
  );
}

export default IncidentsToggle;
