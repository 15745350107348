import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import type { TColorCollection, TThicknessCollection } from '../../components/color-legend';
import { getFacilityLabelKey } from '../../components/form/facilities';
import { IPeriodFormProps } from '../../components/form/period';
import { ISliderBounds, TSliderRange } from '../../components/form/slider';
import { TSectionFeatureCollection, TSectionProperties } from '../../models/sections';
import usePeriod from '../period';

import useHeatmap from './heatmap';

import { Map } from '!maplibre-gl';

export const colors: TColorCollection = [
  { value: '#50315e' },
  { value: '#643d68' },
  { value: '#956485' },
  { value: '#c1939d' },
  { value: '#edd1ca' },
];

export const colorsComparison: TColorCollection = [
  { value: '#a50026' },
  { value: '#d73027' },
  { value: '#f46d43' },
  { value: '#fdae61' },
  { value: '#fee08b' },
  { value: '#ffffbf' },
  { value: '#d9ef8b' },
  { value: '#a6d96a' },
  { value: '#66bd63' },
  { value: '#1a9850' },
  { value: '#006837' },
];

const widths: TThicknessCollection = [
  { value: 2 },
  { value: 4 },
  { value: 6 },
  { value: 8 },
  { value: 10 },
];

function useExtrapolation(
  map: Map | null | undefined,
  period: IPeriodFormProps,
  onClick?: (section?: TSectionProperties) => void,
): {
  initialized: boolean;
  init: () => void;
  update: (
    { features }: TSectionFeatureCollection,
    props: {
      colors?: TColorCollection;
      comparisonEnabled?: boolean;
      currentRange?: TSliderRange;
      primaryBounds: ISliderBounds;
      secondaryBounds?: ISliderBounds;
    },
  ) => void;
  destroy: () => void;
} {
  const periodRef = useRef(period);
  const { t } = useTranslation();
  const { getTitle: getPeriod } = usePeriod();

  useEffect(() => {
    periodRef.current = period;
  }, [period]);

  return useHeatmap(
    map,
    {
      sourceId: 'extrapolation',
      layerId: 'extrapolation',
      primaryCriterion: 'extrapolation',
      secondaryCriterion: 'extrapolation',
      colors: period.comparisonEnabled ? colorsComparison : colors,
      widths,
      disableDirections: true,
    },
    function getTooltipContent({
      facility,
      wayName,
      extrapolation,
      currentExtrapolation,
      prevExtrapolation,
    }) {
      if (!extrapolation && extrapolation !== 0) return '';
      const {
        values: { current, prev },
        comparisonEnabled,
      } = periodRef.current;

      if (comparisonEnabled) {
        if (currentExtrapolation === undefined || prevExtrapolation === undefined) return '';

        return (
          `<h3>${t('cycling-insights.usage.extrapolation.tooltip', {
            period: getPeriod(prev) + ' - ' + getPeriod(current),
          })}</h3>` +
          (wayName ? `<div>${wayName}</div>` : '') +
          (facility && facility !== 'none'
            ? `<div>${t(getFacilityLabelKey(facility))}</div>`
            : '') +
          (extrapolation !== 0 && prevExtrapolation !== 0 && currentExtrapolation !== 0
            ? `<div>${
                extrapolation < 0
                  ? Math.round(
                      ((currentExtrapolation - prevExtrapolation) / prevExtrapolation) * 100,
                    ) + '%'
                  : '+' +
                    Math.round(
                      ((currentExtrapolation - prevExtrapolation) / prevExtrapolation) * 100,
                    ) +
                    '%'
              }</div>`
            : '') +
          `<div>${getPeriod(prev)}: ${t('cycling-insights.usage.travel_simulations.passages', {
            count: Math.round(prevExtrapolation / period.values.prev.nbSpentDays),
          })}<div/>` +
          `<div>${getPeriod(current)}: ${t('cycling-insights.usage.travel_simulations.passages', {
            count: Math.round(currentExtrapolation / period.values.current.nbSpentDays),
          })}<div/>`
        );
      } else {
        return (
          `<h3>${t('cycling-insights.usage.extrapolation.tooltip', {
            period: getPeriod(current),
          })}</h3>` +
          (wayName ? `<div>${wayName}</div>` : '') +
          (facility && facility !== 'none'
            ? `<div>${t(getFacilityLabelKey(facility))}</div>`
            : '') +
          `<div>${t('cycling-insights.usage.travel_simulations.passages', {
            count: Math.round(extrapolation / period.values.current.nbSpentDays),
          })}</div>`
        );
      }
    },
    onClick,
  );
}

export default useExtrapolation;
