import {
  AreaService,
  CyclabilityZone,
  CyclabilityZoneService,
  FileInput,
  Geogroup,
  GeogroupService,
  PartnerService,
  TAdministrativeLevel,
  TFile,
  TGeogroupType,
  backendAdministrativeLevels,
} from '@geovelo-frontends/commons';
import {
  Autocomplete,
  Box,
  Checkbox,
  DialogProps,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  debounce,
} from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { AppContext } from '../app/context';

import Dialog from './dialog';
import ContractForm, { IContractFormValues } from './form/contract-form';

const dialogTitle = 'create-partner-dialog';

export const administrativeLevels: Array<{ key: TAdministrativeLevel; titleKey: string }> = [
  { key: 'country', titleKey: 'commons.administrative_levels.country_one' },
  { key: 'region', titleKey: 'commons.administrative_levels.region_one' },
  { key: 'department', titleKey: 'commons.administrative_levels.department_one' },
  { key: 'epci', titleKey: 'commons.administrative_levels.epci_one' },
  { key: 'city', titleKey: 'commons.administrative_levels.city_one' },
];

export const sizeRanges: {
  [key in TGeogroupType]: Array<{ key: string; min?: number; max?: number }>;
} = {
  company: [
    { key: 'companyLessThan50', max: 50 },
    { key: 'companyBetween50and100', min: 51, max: 100 },
    { key: 'companyBetween100and500', min: 101, max: 500 },
    { key: 'companyMoreThan500', min: 501 },
  ],
  school: [
    { key: 'schoolLessThan50', max: 50 },
    { key: 'schoolBetween50and100', min: 51, max: 100 },
    { key: 'schoolBetween100and500', min: 101, max: 500 },
    { key: 'schoolMoreThan500', min: 501 },
  ],
  association: [
    { key: 'associationLessThan50', max: 50 },
    { key: 'associationBetween50and100', min: 51, max: 100 },
    { key: 'associationBetween100and500', min: 101, max: 500 },
    { key: 'associationMoreThan500', min: 501 },
  ],
  city: [
    { key: 'cityLessThan30000', max: 29_999 },
    { key: 'cityBetween30000and70000', min: 30_000 },
    { key: 'cityBetween70000and300000', min: 70_000, max: 299_999 },
    { key: 'cityMoreThan300000', min: 300_000 },
  ],
  private: [],
  other: [],
};

export const contractTemplatesWithZones = [
  'ANIMATION',
  'cvtc',
  'mobilites-essentielle',
  'mobilites-max',
  'mobilites-max-2023',
  'tourisme-essentielle',
  'tourisme-max',
  'ESSENTIELLE_2024',
  'ESSENTIELLE_2024_INTERNATIONAL_ECOCOMPTEUR',
  'MAX_EXTRAPOLATION',
  'maintenance_cartographique',
  'essentielle_sans_maintenance_cartographique',
];

export const contractTemplatesWithCommunity = [
  'ANIMATION',
  'cvtc',
  'mobilites-essentielle',
  'mobilites-max',
  'mobilites-max-2023',
  'tourisme-essentielle',
  'tourisme-max',
  'MAX_EXTRAPOLATION',
  'essentielle_sans_maintenance_cartographique',
];

type TValues = {
  title: string;
  cyclabilityZone?: CyclabilityZone | null;
  groupType: TGeogroupType | '';
  sizeRangeIndex: number;
  automaticChallenge: boolean;
} & IContractFormValues;

type TProps = Omit<DialogProps, 'onClose'> & { onClose: (newPartnerId?: number) => void };

function CreatePartnerDialog({ onClose, ...props }: TProps): JSX.Element {
  const [icon, setIcon] = useState<TFile>();
  const [cyclabilityZoneSearch, setCyclabilityZoneSearch] = useState('');
  const [cyclabilityZoneSearchResults, setCyclabilityZoneSearchResults] =
    useState<CyclabilityZone[]>();
  const {
    partner: { contractTemplates },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    isValid,
    isSubmitting,
    values,
    touched,
    errors,
    setValues,
    setFieldValue,
    setTouched,
    handleChange,
    handleSubmit,
  } = useFormik<TValues>({
    initialValues: {
      title: '',
      startDate: '',
      endDate: '',
      contractTemplateId: 1,
      administrativeLevel: 'epci',
      cyclabilityZone: null,
      groupType: 'city',
      sizeRangeIndex: 0,
      automaticChallenge: true,
      limitAPIUsage: false,
      apiUsageLimit: 0,
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required(),
      startDate: Yup.string().required(),
      endDate: Yup.string().required(),
      cyclabilityZone: Yup.object().when('contractTemplateId', {
        is: (contractTemplateId: number) => {
          const contractTemplate = contractTemplates?.find(({ id }) => id === contractTemplateId);
          return (
            (contractTemplate?.code &&
              contractTemplatesWithZones.includes(contractTemplate.code)) ||
            false
          );
        },
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.nullable(),
      }),
      groupType: Yup.string().when('contractTemplateId', {
        is: (contractTemplateId: number) => {
          const contractTemplate = contractTemplates?.find(({ id }) => id === contractTemplateId);
          return (
            (contractTemplate?.code &&
              contractTemplatesWithCommunity.includes(contractTemplate.code)) ||
            false
          );
        },
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.nullable(),
      }),
    }),
    onSubmit,
    enableReinitialize: true,
    validateOnMount: true,
  });
  const locked = useRef(false);
  const fetch = useMemo(
    () =>
      debounce(async (search: string, callback: (cyclabilityZones: CyclabilityZone[]) => void) => {
        if (locked.current || !values.administrativeLevel) {
          callback([]);
          locked.current = false;
          return;
        }

        if (values.administrativeLevel === 'world') {
          callback([]);
          return;
        }

        const { zones: cyclabilityZones } = await CyclabilityZoneService.getZones({
          administrativeLevel: backendAdministrativeLevels[values.administrativeLevel],
          query: '{id, administrative_level, geo_polygon, name}',
          search,
          rowsPerPage: 5,
        });

        callback(cyclabilityZones);
      }, 700),
    [values],
  );

  useEffect(() => {
    if (props.open && contractTemplates) {
      const { id: contractTemplateId, apiUsageLimit } = contractTemplates[0];

      setValues({
        title: '',
        startDate: '',
        endDate: '',
        contractTemplateId,
        administrativeLevel: 'epci',
        cyclabilityZone: null,
        groupType: 'city',
        sizeRangeIndex: 0,
        automaticChallenge: true,
        limitAPIUsage: apiUsageLimit !== null,
        apiUsageLimit: apiUsageLimit || 0,
      });
      setTouched({ title: false });
    }
  }, [props.open]);

  useEffect(() => {
    const contractTemplate = contractTemplates?.find(({ id }) => id === values.contractTemplateId);
    if (contractTemplate) {
      const { apiUsageLimit } = contractTemplate;

      setValues({
        ...values,
        limitAPIUsage: apiUsageLimit !== null,
        apiUsageLimit: apiUsageLimit || 0,
      });
    }
  }, [values.contractTemplateId]);

  useEffect(() => {
    let active = true;

    setCyclabilityZoneSearchResults(undefined);
    if (!cyclabilityZoneSearch) return;

    fetch(cyclabilityZoneSearch, (cyclabilityZones: CyclabilityZone[]) => {
      if (active) setCyclabilityZoneSearchResults(cyclabilityZones);
    });

    return () => {
      active = false;
    };
  }, [cyclabilityZoneSearch, fetch]);

  async function onSubmit(
    {
      title,
      startDate,
      endDate,
      contractTemplateId,
      administrativeLevel,
      cyclabilityZone,
      groupType,
      sizeRangeIndex,
      automaticChallenge,
      limitAPIUsage,
      apiUsageLimit,
    }: TValues,
    { setSubmitting, setErrors }: FormikHelpers<TValues>,
  ) {
    const contractTemplate = contractTemplates?.find(({ id }) => id === contractTemplateId);
    if (!contractTemplate) return;

    setSubmitting(true);

    let props: {
      administrativeLevel?: TAdministrativeLevel;
      areaId?: number;
      geogroup?: Geogroup;
    } = {};
    if (contractTemplate.code && contractTemplatesWithZones.includes(contractTemplate.code)) {
      if (!cyclabilityZone) return;

      const areaId = await AreaService.createArea({ partnerTitle: title, cyclabilityZone });

      props = {
        areaId,
        administrativeLevel,
      };
    }

    if (contractTemplate.code && contractTemplatesWithCommunity.includes(contractTemplate.code)) {
      if (groupType !== 'city') {
        setErrors({ groupType: 'invalid' });
        return;
      }

      const { min, max } = sizeRanges.city[sizeRangeIndex] || {
        min: undefined,
        max: undefined,
      };

      const geogroup = await GeogroupService.addGeogroup({
        type: 'city',
        name: title,
        min,
        max,
        icon: icon instanceof File ? icon : null,
        automaticChallengeStatus: automaticChallenge ? 'automaticDistance' : 'disabled',
        publicationStatus: 'published',
      });

      props.geogroup = geogroup;
    }

    setErrors({});

    try {
      const { id } = await PartnerService.addPartner({
        title,
        ...props,
        icon: icon instanceof File ? icon : undefined,
        contracts: [
          {
            startDate,
            endDate,
            apiUsageLimit: limitAPIUsage ? apiUsageLimit || 0 : null,
            contractTemplateId,
          },
        ],
      });

      onClose(id);
    } catch (err) {
      enqueueSnackbar(t('cycling-insights.create_partner_dialog.not_created'), {
        variant: 'error',
      });
    }

    setSubmitting(false);
  }

  const contractTemplate = contractTemplates?.find(({ id }) => id === values.contractTemplateId);
  const zoneRequired =
    contractTemplate?.code && contractTemplatesWithZones.includes(contractTemplate.code);
  const communityRequired =
    contractTemplate?.code && contractTemplatesWithCommunity.includes(contractTemplate.code);

  return (
    <Dialog
      disableEscapeKeyDown
      isForm
      confirmDisabled={!isValid}
      confirmTitle={<Trans i18nKey="commons.actions.create" />}
      dialogTitle={dialogTitle}
      loading={isSubmitting}
      maxWidth="sm"
      onCancel={() => onClose()}
      onConfirm={handleSubmit}
      scroll="paper"
      title={<Trans i18nKey="cycling-insights.create_partner_dialog.title" />}
      {...props}
    >
      <Typography component="p" variant="caption">
        <Trans i18nKey="cycling-insights.create_partner_dialog.description" />
      </Typography>
      <TextField
        fullWidth
        required
        disabled={isSubmitting}
        error={touched.title && Boolean(errors.title)}
        label={t('commons.title')}
        margin="dense"
        name="title"
        onChange={handleChange}
        size="small"
        value={values.title}
        variant="outlined"
      />
      <ContractForm<TValues>
        errors={errors}
        handleChange={handleChange}
        isSubmitting={isSubmitting}
        touched={touched}
        values={values}
      >
        <Box display="flex" flexDirection="column" gap={1}>
          {(zoneRequired || communityRequired) && (
            <Box display="flex" flexDirection="column" gap={1} marginY={2}>
              {zoneRequired && (
                <>
                  <Divider />
                  <Box display="flex" flexDirection="column">
                    <FormControl disabled={isSubmitting} margin="none">
                      <FormLabel component="legend" id="administrative-level-label">
                        <Typography variant="caption">
                          <Trans i18nKey="cycling-insights.create_partner_dialog.administrative_level" />
                        </Typography>
                      </FormLabel>
                      <Select
                        labelId="administrative-level-label"
                        name="administrativeLevel"
                        onChange={handleChange}
                        size="small"
                        value={values.administrativeLevel}
                      >
                        {administrativeLevels.map(({ key, titleKey }) => (
                          <MenuItem key={key} value={key}>
                            {t(titleKey)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Autocomplete
                      disablePortal
                      includeInputInList
                      disabled={isSubmitting}
                      forcePopupIcon={Boolean(cyclabilityZoneSearch && !values.cyclabilityZone)}
                      getOptionKey={(option) => option.id}
                      getOptionLabel={(option) => option.name}
                      loading={Boolean(cyclabilityZoneSearch) && !values.cyclabilityZone}
                      loadingText={<Trans i18nKey="commons.loading" />}
                      noOptionsText={
                        cyclabilityZoneSearch && <Trans i18nKey="commons.no_result_found" />
                      }
                      onChange={(_, value) => {
                        locked.current = true;
                        setValues({ ...values, cyclabilityZone: value || null });
                      }}
                      onInputChange={(_, value) => {
                        setTimeout(() => {
                          setCyclabilityZoneSearch(value);
                        }, 10);
                      }}
                      options={cyclabilityZoneSearchResults || []}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          error={Boolean(errors.cyclabilityZone && touched.cyclabilityZone)}
                          label={t('cycling-insights.create_partner_dialog.cyclability_zone')}
                          margin="dense"
                          size="small"
                          variant="outlined"
                        />
                      )}
                      value={values.cyclabilityZone}
                    />
                  </Box>
                </>
              )}
              {communityRequired && (
                <>
                  <Divider />
                  <FormControl disabled={isSubmitting} margin="none">
                    <FormLabel component="legend" id="group-type-label">
                      <Typography variant="caption">
                        <Trans i18nKey="cycling-insights.create_partner_dialog.group_type" />
                      </Typography>
                    </FormLabel>
                    <Select
                      disabled
                      labelId="administrative-level-label"
                      name="groupType"
                      onChange={(event) => {
                        if (event.target.value === '') {
                          setFieldValue('sizeRangeIndex', 0);
                        } else if (event.target.value !== 'private') {
                          setFieldValue(
                            'sizeRangeIndex',
                            Math.min(
                              values.sizeRangeIndex,
                              sizeRanges[event.target.value as TGeogroupType].length - 1,
                            ),
                          );
                        } else {
                          setFieldValue('address', null);
                        }
                        handleChange(event);
                      }}
                      size="small"
                      value={values.groupType}
                    >
                      <MenuItem value="city">
                        <Trans i18nKey="cycling-insights.create_partner_dialog.group_types.city" />
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.automaticChallenge}
                        disabled={isSubmitting}
                        name="automaticChallenge"
                        onChange={handleChange}
                        size="small"
                        value={values.automaticChallenge || false}
                      />
                    }
                    label={
                      <Trans i18nKey="cycling-insights.create_partner_dialog.automatic_challenges" />
                    }
                  />
                </>
              )}
            </Box>
          )}
          <Divider />
          <Box display="flex" flexDirection="column">
            <Typography color="textSecondary" variant="caption">
              {t('cycling-insights.edit_partner_dialog.logo')}
            </Typography>
            <FileInput
              disabled={isSubmitting}
              file={icon}
              onChange={setIcon}
              size="small"
              type="image"
            />
          </Box>
        </Box>
      </ContractForm>
    </Dialog>
  );
}

export default CreatePartnerDialog;
