import {
  Counter,
  CounterService,
  Period,
  StatsService,
  TPeriod,
  VirtualCounterService,
  currentMonth,
  prevMonth,
  roadTypesLabels,
  useUnits,
} from '@geovelo-frontends/commons';
import { Place } from '@mui/icons-material';
import { Box, Skeleton, Typography, useTheme } from '@mui/material';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { AppContext } from '../../../../app/context';
import PeriodForm, { IPeriodFormValues } from '../../../../components/form/period';
import Paper from '../../../../components/paper';
import Ranking from '../../../../components/ranking';
import TabIntroduction from '../../../../components/tab-introduction';
import useCounters from '../../../../hooks/map/counters';
import useExtrapolation from '../../../../hooks/map/extrapolation';
import { colors as _colors } from '../../../../hooks/map/roads-uses';
import useQueryParams from '../../../../hooks/query-params';
import useSectionsStats from '../../../../hooks/sections-stats';
import { TOutletContext } from '../../../../layouts/page/container';
import { TSectionProperties } from '../../../../models/sections';
import { IBicycleObservatoryPageContext } from '../../context';

const minPeriod = new Period(
  'month',
  moment('2021-10').startOf('month'),
  moment('2021-10').endOf('month'),
);

function ExtrapolationForm(context: IBicycleObservatoryPageContext & TOutletContext): JSX.Element {
  const {
    period,
    roadsUse,
    setLoading,
    counters: { list, selectedKey, selectKey, setList, setStats },
  } = context;
  const {
    data,
    bounds,
    currentRange,
    distancesByFacilities,
    selectSection,
    setQuartiles,
    selectFacilities,
    setDistancesByFacilities,
  } = roadsUse;
  const [initialized, setInitialized] = useState(false);
  const {
    map: { current: currentMap, baseLayer, countersShowed },
    partner: { current: currentPartner },
  } = useContext(AppContext);
  const [maxPeriod, setMaxPeriod] = useState(getMaxPeriod);
  const {
    initialized: extrapolationInitialized,
    init: initExtrapolation,
    update: updateExtrapolation,
    destroy: destroyExtrapolation,
  } = useExtrapolation(currentMap, period, handleSectionClick);
  const { timeoutRef } = useSectionsStats({
    isExtrapolated: true,
    hasChartComparison: true,
    primaryCriterion: 'extrapolation',
    initialized: extrapolationInitialized,
    period,
    ...roadsUse,
    setLoading,
  });
  const { t } = useTranslation();
  const theme = useTheme();
  const { toDistance } = useUnits();
  const { enqueueSnackbar } = useSnackbar();
  const { getPeriods } = useQueryParams();
  const [customPeriodTypes] = useState<{
    defaultPeriods: IPeriodFormValues;
    enabledTypes: TPeriod[];
  }>({
    defaultPeriods: getPeriods(moment().get('date') <= 7 ? prevMonth : currentMonth),
    enabledTypes: ['month'],
  });
  const {
    initialized: countersLayersInitialized,
    init: initCountersLayers,
    update: updateCountersLayers,
    clear: clearCountersLayers,
    destroy: destroyCountersLayers,
    selectCounter,
  } = useCounters(currentMap, countersShowed ? list : [], theme, {
    onClick: handleMarkerClick,
  });

  useEffect(() => {
    selectFacilities([
      'cycleway',
      'lane',
      'greenway',
      'opposite',
      'sharebusway',
      'mixedfacilities',
      'other',
      'none',
    ]);

    if (
      period.comparisonEnabled ||
      period.values.timePeriod !== 'all_day' ||
      period.values.dayPeriod !== 'all'
    ) {
      period.enableComparison?.(false);
      period.setValues({ ...period.values, timePeriod: 'all_day', dayPeriod: 'all' });
    }
    setInitialized(true);

    return () => {
      clearCountersLayers();
      selectKey(null);
      setStats(undefined);
    };
  }, []);

  useEffect(() => setMaxPeriod(getMaxPeriod()), [currentPartner]);

  useEffect(() => {
    if (initialized) getDistancesTravelled();

    return () => {
      setDistancesByFacilities?.(undefined);
    };
  }, [initialized, period.values.current, currentPartner]);

  useEffect(() => {
    if (currentMap) {
      initExtrapolation();
      initCountersLayers();
    }

    return () => {
      destroyExtrapolation();
      destroyCountersLayers();
    };
  }, [currentMap]);

  useEffect(() => {
    if (countersLayersInitialized && list) updateCountersLayers(selectedKey, { draggable: false });
  }, [countersLayersInitialized, list]);

  useEffect(() => {
    if (selectedKey) {
      const [type, _id] = selectedKey.split('_');
      const isVirtual = type === 'virtual';
      const id = parseInt(_id);
      selectCounter(list?.find((counter) => counter.id === id && counter.isVirtual === isVirtual));
    }

    return () => selectCounter(undefined);
  }, [list, selectedKey]);

  useEffect(() => {
    if (countersShowed) getCounters();
    else clearCountersLayers();
  }, [countersShowed]);

  useEffect(() => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    if (!extrapolationInitialized) return;

    const { comparisonEnabled } = period;

    if (data && bounds && currentRange) {
      timeoutRef.current = setTimeout(() => {
        let _quartiles: number[] | undefined;

        if (!comparisonEnabled && data.features.length > 0) {
          const { min, max } = bounds;
          const frequencies = data.features
            .map(({ properties: { frequency, extrapolation } }) =>
              currentPartner?.dashboardTabsPermissions.usageRoadsUse === 'extrapolated'
                ? extrapolation
                : frequency,
            )
            .filter((frequency) => frequency && frequency >= min && frequency <= max)
            .sort((a, b) => (a && b ? a - b : 1));

          _quartiles = [
            ...new Set([
              min,
              ..._colors
                .slice(0, Math.min(_colors.length - 1, frequencies.length - 1))
                .map((_, index) =>
                  Math.round(
                    frequencies[Math.round(frequencies.length * ((index + 1) / _colors.length))] ||
                      0,
                  ),
                ),
              max,
            ]),
          ];

          setQuartiles?.(_quartiles);
        }

        const colors = baseLayer === 'dark' ? _colors : [..._colors].reverse();
        updateExtrapolation(data, {
          colors: _quartiles
            ? colors.slice(0, _quartiles.length - 1).map(({ value }, index) => ({
                value,
                min: _quartiles?.[index],
                max: _quartiles?.[index + 1],
              }))
            : undefined,
          comparisonEnabled,
          currentRange,
          primaryBounds: bounds,
          secondaryBounds: bounds,
        });
      }, 300);
    } else {
      updateExtrapolation(
        { type: 'FeatureCollection', features: [] },
        {
          comparisonEnabled: period.comparisonEnabled,
          primaryBounds: { min: 0, max: 1 },
          secondaryBounds: bounds,
        },
      );
    }
  }, [baseLayer, extrapolationInitialized, data, currentRange]);

  function getMaxPeriod() {
    return currentPartner?.dashboardTabsPermissions.usageRoadsUse === 'extrapolated'
      ? moment().get('date') === 1
        ? prevMonth.getPrevPeriod()
        : prevMonth
      : moment().get('date') <= 7
        ? prevMonth
        : undefined;
  }

  async function getCounters() {
    try {
      const virtualCounters = await VirtualCounterService.getVirtualCounters();

      virtualCounters.sort((a, b) => b.id - a.id);

      const _counters: Counter[] = [];

      if (
        currentPartner?.dashboardTabsPermissions.usageRoadsUse === 'extrapolated' &&
        currentPartner?.hasRealCounters
      ) {
        const realCounters = await CounterService.getCounters();

        realCounters.sort((a, b) => a.id - b.id);

        _counters.push(...realCounters);
      }

      setList([..._counters, ...virtualCounters]);
    } catch {
      enqueueSnackbar(t('cycling-insights.usage.point_attendance.server_error'), {
        variant: 'error',
      });
      setList([]);
    }
  }

  async function getDistancesTravelled() {
    if (!currentPartner) return;

    try {
      const [currentDistances] = await Promise.all([
        StatsService.getDistancesTravelled(currentPartner.id, period.values.current.toIPeriod()),
      ]);
      setDistancesByFacilities?.(currentDistances);
    } catch {
      enqueueSnackbar(t('cycling-insights.usage.roads_use.server_error'), {
        variant: 'error',
      });
    }
  }

  function handleMarkerClick(key?: string) {
    selectKey(key || null);
  }

  function handleSectionClick(section?: TSectionProperties) {
    selectKey(null);
    if (section) selectSection?.(section);
    else selectSection?.();
  }

  return (
    <Box display="flex" flexDirection="column" gap={3} minHeight="100%">
      <TabIntroduction title="cycling-insights.bicycle_observatory.introduction.roads_use" />

      <Paper
        header={
          <PeriodForm
            disableComparison
            disablePadding
            disablePeriodTypeChange
            customPeriodTypes={customPeriodTypes}
            maxPeriod={maxPeriod}
            minPeriod={minPeriod}
            {...period}
          />
        }
      >
        <Typography display="flex">
          <Trans i18nKey="cycling-insights.usage.roads_use.total_extrapolation" />
          {distancesByFacilities ? (
            toDistance(distancesByFacilities.allExtrapolated || 0)
          ) : (
            <Skeleton variant="text" width={24} />
          )}
        </Typography>
      </Paper>
      <Ranking
        data={data?.features
          .sort((a, b) =>
            a.properties.sectionDistanceTraveled !== undefined &&
            b.properties.sectionDistanceTraveled !== undefined
              ? (b.properties.sectionDistanceTraveled || 0) -
                (a.properties.sectionDistanceTraveled || 0)
              : (b.properties.distanceTraveled || 0) - (a.properties.distanceTraveled || 0),
          )
          .slice(0, 5)
          .reduce<{ id?: number; title: string; subtitle?: string; value: number }[]>(
            (
              res,
              {
                id,
                properties: {
                  id: propertiesId,
                  wayName,
                  distanceTraveled,
                  roadType,
                  sectionDistanceTraveled,
                },
              },
            ) => {
              if (data.features[0]?.properties.sectionDistanceTraveled !== undefined) {
                if (typeof id !== 'string' || id.indexOf('backward') < 0)
                  res.push({
                    id: propertiesId,
                    title:
                      wayName ||
                      t(roadTypesLabels[roadType || 'unclassified']) +
                        ' - ' +
                        propertiesId.toString(),
                    value: sectionDistanceTraveled || 0,
                  });
                return res;
              } else {
                res.push({
                  id: propertiesId,
                  title:
                    wayName ||
                    t(roadTypesLabels[roadType || 'unclassified']) +
                      ' - ' +
                      propertiesId.toString(),
                  value: distanceTraveled || 0,
                });
                return res;
              }
            },
            [],
          )}
        formatProgress={(value) =>
          distancesByFacilities?.all
            ? `${((value / distancesByFacilities?.all) * 100).toFixed(2)} %`
            : ''
        }
        onClick={(id) => {
          const feature = data?.features.find(({ properties }) => properties.id === id);
          const coordinates = feature?.geometry.coordinates;

          if (coordinates) {
            const [lng, lat] = coordinates[Math.round(coordinates.length / 2)];
            currentMap?.flyTo({
              center: { lat, lng },
              zoom: 18,
              animate: false,
            });
          }
          selectSection?.(feature?.properties);
        }}
        startIcon={<Place sx={{ color: '#E76685' }} />}
        subtitle={
          <Typography marginBottom={2} marginTop={1} variant="body2">
            <Trans i18nKey="cycling-insights.usage.roads_use.ranking.subtitle" />
          </Typography>
        }
        title="cycling-insights.usage.roads_use.ranking.title"
      />
    </Box>
  );
}

export default ExtrapolationForm;
