import { useAmplitudeTracker, useFileSaver } from '@geovelo-frontends/commons';
import { FileDownloadOutlined } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { useContext, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

import { AppContext } from '../../../../app/context';
import { TOutletContext } from '../../../../layouts/page/container';
import PageContentLayout from '../../../../layouts/page/content';
import { IBicycleObservatoryPageContext } from '../../context';

import SuddenBrakingsForm from './form';

function SuddenBrakingsTab(): JSX.Element {
  const context = useOutletContext<IBicycleObservatoryPageContext & TOutletContext>();
  const {
    period,
    header: { setActions },
    suddenBrakings: { data },
  } = context;
  const {
    partner: { current: currentPartner },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { downloadBlob } = useFileSaver();
  const { trackEvent } = useAmplitudeTracker();

  useEffect(() => {
    setActions(
      <Tooltip title={<Trans i18nKey="cycling-insights.facilities.actions.download" />}>
        <span>
          <IconButton
            color="primary"
            disabled={!data}
            onClick={() => {
              handleDownload();
            }}
            size="small"
          >
            <FileDownloadOutlined />
          </IconButton>
        </span>
      </Tooltip>,
    );
  }, [data]);

  function handleDownload() {
    if (!data) return;

    trackEvent('File Downloaded', {
      pathname: `${window.location.host}${window.location.pathname}`,
      partner_id: currentPartner?.id,
      partner_code: currentPartner?.code,
      file: 'Stopping Areas',
    });

    const {
      values: { current: currentPeriod },
    } = period;

    downloadBlob(
      `stats-${t('cycling-insights.usage.navigation.road_use')
        .replace(/ /g, '_')
        .toLowerCase()}${currentPeriod.from.format('YYYY-MM-DD')}_${currentPeriod.to.format(
        'YYYY-MM-DD',
      )}.geojson`,
      new Blob(
        [
          JSON.stringify(
            {
              ...data,
              features: data.features,
            },
            null,
            2,
          ),
        ],
        { type: 'application/json' },
      ),
    );
  }

  return <PageContentLayout leftPanel={<SuddenBrakingsForm {...context} />} />;
}

export default SuddenBrakingsTab;
