import { TReportTypeCode, TStatus } from '@geovelo-frontends/commons';
import { FilterList } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  ListItemText,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useContext, useState } from 'react';
import { Trans } from 'react-i18next';

import { AppContext } from '../../app/context';

const statuses: TStatus[] = ['OPEN', 'CLOSED', 'ONGOING'];
const reviewsCount: number[] = [-1, 0, 1, 2, 3];

function ReportsFilters({
  isBindToOSM,
  selectedReviewsCount,
  selectedTypeCodes,
  selectedStatuses,
  selectReviewsCount,
  selectTypeCodes,
  selectStatuses,
}: {
  isBindToOSM?: boolean;
  selectedReviewsCount?: number;
  selectedTypeCodes: TReportTypeCode[];
  selectedStatuses: TStatus[];
  selectReviewsCount?: (count: number) => void;
  selectTypeCodes: (typeCodes: TReportTypeCode[]) => void;
  selectStatuses: (statuses: (TStatus | 'ONGOING')[]) => void;
}): JSX.Element {
  const [typeMenuAnchorEl, setTypeMenuAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [statusMenuAnchorEl, setStatusMenuAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [reviewsMenuAnchorEl, setReviewsMenuAnchorEl] = useState<HTMLButtonElement | null>(null);
  const {
    report: { types },
  } = useContext(AppContext);

  return (
    <Box alignItems="center" display="flex" gap={2} justifyContent="flex-end">
      {selectReviewsCount && (
        <>
          <Button
            color="inherit"
            endIcon={<FilterList />}
            onClick={({ currentTarget }) => setReviewsMenuAnchorEl(currentTarget)}
            sx={{ textTransform: 'initial' }}
            variant="outlined"
          >
            <Trans i18nKey="commons.stats.reviews_label" />
          </Button>
          <Menu
            keepMounted
            anchorEl={reviewsMenuAnchorEl}
            id="reviews-menu"
            MenuListProps={{ style: { width: 250 } }}
            onClose={() => setReviewsMenuAnchorEl(null)}
            open={Boolean(reviewsMenuAnchorEl)}
          >
            <RadioGroup defaultValue={selectedReviewsCount}>
              {reviewsCount.map((index) => (
                <MenuItem dense key={index} onClick={() => selectReviewsCount(index)}>
                  <FormControlLabel
                    control={<Radio />}
                    label={
                      <Trans
                        count={index}
                        i18nKey={
                          index === -1
                            ? 'commons.all'
                            : `cycling-insights.reports.osm_cartographic_reports.table.${
                                index === 3 ? 'more_than_two_reviews' : 'reviews'
                              }`
                        }
                        values={{ count: index }}
                      />
                    }
                    value={index}
                  />
                </MenuItem>
              ))}
            </RadioGroup>
          </Menu>
        </>
      )}
      <Button
        color="inherit"
        endIcon={<FilterList />}
        onClick={({ currentTarget }) => setTypeMenuAnchorEl(currentTarget)}
        sx={{ textTransform: 'initial' }}
        variant="outlined"
      >
        <Trans i18nKey="commons.stats.type_label" />
      </Button>
      <Menu
        keepMounted
        anchorEl={typeMenuAnchorEl}
        id="type-menu"
        MenuListProps={{ style: { width: 250 } }}
        onClose={() => setTypeMenuAnchorEl(null)}
        open={Boolean(typeMenuAnchorEl)}
      >
        {types
          ?.filter(({ code, isBindToOSM }) => isBindToOSM && code !== 'support')
          .map(({ code, titleKey }) => {
            if (code === 'support' || code === 'exclusionZone') return <div key={code} />;

            const checked = selectedTypeCodes.indexOf(code) > -1;

            return (
              <MenuItem
                dense
                key={code}
                onClick={() =>
                  checked
                    ? selectTypeCodes(selectedTypeCodes.filter((key) => key !== code))
                    : selectTypeCodes([...selectedTypeCodes, code])
                }
                value={code}
              >
                <Checkbox checked={checked} style={{ padding: 4, marginRight: 8 }} />
                <ListItemText primary={<Trans i18nKey={titleKey} />} />
              </MenuItem>
            );
          })}
      </Menu>
      <Button
        color="inherit"
        endIcon={<FilterList />}
        onClick={({ currentTarget }) => setStatusMenuAnchorEl(currentTarget)}
        sx={{ textTransform: 'initial' }}
        variant="outlined"
      >
        <Trans i18nKey="commons.status" />
      </Button>
      <Menu
        keepMounted
        anchorEl={statusMenuAnchorEl}
        id="type-menu"
        MenuListProps={{ style: { width: 250 } }}
        onClose={() => setStatusMenuAnchorEl(null)}
        open={Boolean(statusMenuAnchorEl)}
      >
        {statuses.map((status) => {
          const checked = selectedStatuses.indexOf(status) > -1;

          return (
            <MenuItem
              dense
              key={status}
              onClick={() =>
                checked
                  ? selectStatuses(selectedStatuses.filter((key) => key !== status))
                  : selectStatuses([...selectedStatuses, status])
              }
              value={status}
            >
              <Checkbox checked={checked} style={{ padding: 4, marginRight: 8 }} />
              <ListItemText
                primary={
                  <Trans
                    i18nKey={`commons.statuses.${
                      status === 'OPEN'
                        ? isBindToOSM
                          ? 'unprocessed'
                          : 'open'
                        : status === 'ONGOING'
                          ? 'ongoing'
                          : isBindToOSM
                            ? 'processed'
                            : 'closed'
                    }`}
                  />
                }
              />
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
}

export default ReportsFilters;
