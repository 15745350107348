import { TSuddenBrakingsFeatureCollection, useLayers, useSource } from '@geovelo-frontends/commons';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  TColorCollection,
  TThicknessCollection,
  findIndexInIntervals,
  getColors,
} from '../../components/color-legend';
import { ISliderBounds } from '../../components/form/slider';

import { Map, Popup } from '!maplibre-gl';

const sourceId = 'sudden-brakings';
const layerId = 'sudden-brakings';

export const defaultColors: TColorCollection = [
  { value: '#50315e' },
  { value: '#643d68' },
  { value: '#956485' },
  { value: '#c1939d' },
  { value: '#edd1ca' },
];

const radiuses: TThicknessCollection = [
  { value: 4 },
  { value: 5 },
  { value: 6 },
  { value: 7 },
  { value: 8 },
  { value: 9 },
  { value: 10 },
  { value: 11 },
  { value: 12 },
  { value: 13 },
  { value: 14 },
];

function useSuddenBrakings(map: Map | null | undefined): {
  clear: () => void;
  destroy: () => void;
  init: () => void;
  initialized: boolean;
  update: (
    { features }: TSuddenBrakingsFeatureCollection,
    props: {
      colors?: TColorCollection;
      primaryBounds: ISliderBounds;
      secondaryBounds?: ISliderBounds;
    },
  ) => void;
} {
  const [initialized, setInitialized] = useState(false);
  const { t } = useTranslation();
  const initializedRef = useRef(false);
  const highlightedAreaTooltip = useRef<Popup>();
  const { addGeoJSONSource, getGeoJSONSource, updateGeoJSONSource, clearGeoJSONSource } = useSource(
    map,
    sourceId,
  );
  const { addCircleLayer } = useLayers(map);
  let hoveredAreaId: number | string | undefined;

  function init() {
    if (!map || initializedRef.current) return;
    if (getGeoJSONSource()) {
      initializedRef.current = true;
      setInitialized(true);
      return;
    }

    addGeoJSONSource();

    highlightedAreaTooltip.current = new Popup({
      className: 'map-tooltip',
      closeButton: false,
    });

    addCircleLayer(
      layerId,
      sourceId,
      {
        'circle-radius': ['get', 'radius'],
        'circle-color': ['get', 'color'],
      },
      { before: map.getLayer('accidents') ? 'accidents' : undefined },
    );

    map.on('mousemove', layerId, ({ features }) => {
      if (features && features.length > 0) {
        const { geometry, properties } = features[0];
        if (geometry.type !== 'Point' || !properties) return;

        highlightedAreaTooltip.current
          ?.setHTML(
            `<h3>${t('cycling-insights.usage.sudden_brakings.tooltip.title', {
              count: properties.nb_hard_braking,
            })}</h3>`,
          )
          .setLngLat({ lat: geometry.coordinates[1], lng: geometry.coordinates[0] })
          .addTo(map);

        if (hoveredAreaId !== properties?.id) {
          hoveredAreaId = properties?.id;
          if (hoveredAreaId) {
            map.getCanvas().style.cursor = 'pointer';
          } else {
            clearHighlight();
          }
        }
      }
    });

    map.on('mouseleave', layerId, () => {
      map.getCanvas().style.cursor = '';
      clearHighlight();
    });

    initializedRef.current = true;
    setInitialized(true);
  }

  function update(
    collection: TSuddenBrakingsFeatureCollection,
    {
      colors: customColors,
      primaryBounds,
      secondaryBounds,
    }: {
      colors?: TColorCollection;
      primaryBounds: ISliderBounds;
      secondaryBounds?: ISliderBounds;
    },
  ) {
    const colors = getColors({ colors: customColors || defaultColors, bounds: primaryBounds });

    updateGeoJSONSource({
      ...collection,
      features: collection.features.map(({ properties, ...feature }) => {
        const colorIndex = findIndexInIntervals<string>(properties.average, primaryBounds, colors);
        const radiusIndex = secondaryBounds
          ? findIndexInIntervals<number>(properties.nb_hard_braking, secondaryBounds, radiuses)
          : null;

        return {
          ...feature,
          properties: {
            ...properties,
            color: colorIndex !== null ? colors[colorIndex].value : undefined,
            radius:
              radiuses[radiusIndex !== null ? radiusIndex : Math.floor(radiuses.length / 2)].value,
          },
        };
      }),
    });
  }

  function clearHighlight() {
    highlightedAreaTooltip.current?.remove();

    hoveredAreaId = undefined;
  }

  function clear() {
    clearGeoJSONSource();
    clearHighlight();
  }

  function destroy() {
    clear();

    initializedRef.current = false;
    setInitialized(false);
  }

  return { initialized, init, update, clear, destroy };
}

export default useSuddenBrakings;
