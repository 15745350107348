import {
  GeoveloInlineIcon,
  IsochroneService,
  useAmplitudeTracker,
  useFileSaver,
} from '@geovelo-frontends/commons';
import { ShareOutlined } from '@mui/icons-material';
import { Box, Menu, MenuItem, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import html2canvas from 'html2canvas';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import styled from 'styled-components';

import { AppContext } from '../../../../app/context';
import Button from '../../../../components/button';
import DepartureIcon from '../../../../components/icons/departure';
import NorthIcon from '../../../../components/icons/north';
import Map from '../../../../components/map';
import IsochronesLegendControl from '../../../../components/map/isochrones-legend-control';
import useIsochrones from '../../../../hooks/map/isochrones';
import { TOutletContext } from '../../../../layouts/page/container';
import PageContentLayout from '../../../../layouts/page/content';
import { TCartographicDataPageContext } from '../../context';

import IsochronesForm from './form';

import { Map as MaplibreMap } from '!maplibre-gl';

function IsochronesTab(): JSX.Element {
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [downloadingPNG, setDownloadingPNG] = useState(false);
  const [downloadingSHP, setDownloadingSHP] = useState(false);
  const [printableMap, setPrintableMap] = useState<MaplibreMap>();
  const printRef = useRef<HTMLDivElement | null>(null);
  const printableMapDataURLRef = useRef<string>();
  const context = useOutletContext<TCartographicDataPageContext & TOutletContext>();
  const {
    isochrones: { data, departures, durations, eBikeEnabled, profile },
  } = context;
  const {
    map: { baseLayer },
    partner: { current: currentPartner },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { downloadBlob } = useFileSaver();
  const { trackEvent } = useAmplitudeTracker();
  const {
    initialized: printableMapInitialized,
    init: initPrintableMapLayers,
    setDepartures: setDeparturesOnPrintableMap,
    update: updatePrintableMapLayers,
    destroy: destroyPrintableMapLayers,
  } = useIsochrones(printableMap, theme);

  useEffect(() => {
    if (printableMap) initPrintableMapLayers();

    return () => destroyPrintableMapLayers();
  }, [printableMap]);

  useEffect(() => {
    if (printableMapInitialized) setDeparturesOnPrintableMap(departures, false);
  }, [printableMapInitialized, departures]);

  useEffect(() => {
    if (printableMapInitialized) updatePrintableMapLayers(data);
  }, [printableMapInitialized, data]);

  async function handleShpDownload() {
    setDownloadingSHP(true);
    trackEvent('File Downloaded', {
      pathname: `${window.location.host}${window.location.pathname}`,
      partner_id: currentPartner?.id,
      partner_code: currentPartner?.code,
      file: 'Isochrones',
    });

    try {
      const locations = departures.map(
        ({
          address,
          point: {
            coordinates: [longitude, latitude],
          },
        }) => ({
          title: address,
          latitude,
          longitude,
        }),
      );

      let _durations: number[] = [];
      Object.values(durations).forEach((value) => {
        if (typeof value === 'number' && value > 0) _durations.push(value * 60);
      });
      _durations = [...new Set(_durations)];

      const blob = await IsochroneService.getIsochronesFile({
        durations: _durations,
        locations,
        profile,
        eBikeEnabled,
      });

      downloadBlob(`isochrones.zip`, blob);
    } catch {
      enqueueSnackbar(t('cycling-insights.facilities.isochrones.server_error'), {
        variant: 'error',
      });
    }
    setDownloadingSHP(false);
    setMenuAnchorEl(null);
  }

  async function handlePNGDownload() {
    setDownloadingPNG(true);

    printableMapDataURLRef.current = undefined;

    if (printRef.current) {
      printableMapDataURLRef.current = printableMap?.getCanvas().toDataURL();

      await new Promise((resolve) => setTimeout(() => resolve(true), 100));

      const canvas = await html2canvas(printRef.current, {
        scale: 1,
        width: printRef.current.clientWidth,
        height: printRef.current.clientHeight,
        useCORS: true,
        allowTaint: true,
      });

      const blob = await new Promise<Blob>((resolve, reject) => {
        try {
          canvas.toBlob(
            (blob) => {
              if (!blob) throw new Error('no blob');
              else resolve(blob);
            },
            'image/png',
            1.0,
          );
        } catch (err) {
          reject(err);
        }
      });

      downloadBlob(`isochrones.png`, blob);
    }

    printableMapDataURLRef.current = undefined;

    setDownloadingPNG(false);
  }

  return (
    <>
      <PageContentLayout
        leftPanel={<IsochronesForm {...context} />}
        rightPanel={
          <Button
            color="primary"
            disabled={!data}
            onClick={({ currentTarget }) => setMenuAnchorEl(currentTarget)}
            startIcon={<ShareOutlined />}
            variant="contained"
          >
            <Trans i18nKey="commons.actions.export" />
          </Button>
        }
      />
      <Menu
        anchorEl={menuAnchorEl}
        id="export-menu"
        MenuListProps={{ 'aria-labelledby': 'export-menu-button' }}
        onClose={() => setMenuAnchorEl(null)}
        open={Boolean(menuAnchorEl)}
      >
        <MenuItem
          disabled={downloadingSHP}
          key="shp"
          onClick={() => {
            handleShpDownload();
            setMenuAnchorEl(null);
          }}
          value="shp"
        >
          Shapefile
        </MenuItem>
        <MenuItem
          disabled={downloadingPNG}
          key="png"
          onClick={() => {
            handlePNGDownload();
            setMenuAnchorEl(null);
          }}
          value="png"
        >
          PNG
        </MenuItem>
      </Menu>
      {data && (
        <Box bottom={-10000} left={0} position="fixed" ref={printRef} width={3178} zIndex={-1}>
          <Box display="flex" flexDirection="column" gap={10} paddingX={3} paddingY={10}>
            <Box
              alignItems="flex-start"
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography fontWeight={600} variant="h1">
                <Trans i18nKey="cycling-insights.bicycle_observatory.navigation.isochrones" />
              </Typography>
              <Box alignItems="center" display="flex" flexDirection="column">
                <GeoveloInlineIcon sx={{ height: 100, width: 'auto' }} />
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" gap={1}>
              <Typography variant="h3">Estimation des temps de trajet à vélo depuis :</Typography>
              <Box alignItems="center" display="flex" flexDirection="row" gap={1}>
                <DepartureIcon sx={{ height: 48, width: 48 }} />
                <Typography lineHeight={1} variant="h4">
                  {departures[0]?.address}
                </Typography>
              </Box>
            </Box>
            <Box height={3130} position="relative" width={3130}>
              {printableMapDataURLRef.current ? (
                <img height="100%" src={printableMapDataURLRef.current} width="100%" />
              ) : (
                <Box
                  disableInteractions
                  disableZoomControl
                  hasScaleControl
                  baseLayer={baseLayer}
                  component={Map}
                  fitBoundsOptions={{ padding: 50 }}
                  height="100%"
                  mapId="printable-isochrones-map"
                  onInit={setPrintableMap}
                  width="100%"
                >
                  <StyledNorthIcon />
                </Box>
              )}
            </Box>
            <Box
              alignItems="flex-start"
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Box display="flex" flexDirection="column" gap={3}>
                <Typography variant="h3">Temps en minutes</Typography>
                <IsochronesLegendControl horizontal={true} isochrones={data} size="large" />
              </Box>
              <Box display="flex" flexDirection="column" gap={3}>
                <Typography variant="h3">
                  Cette accessibilité est calculée avec les hypothèses suivantes :
                </Typography>
                <Box paddingLeft={10}>
                  <Typography component="ul" variant="h4">
                    <li>{eBikeEnabled ? 'vélo électrique' : 'vélo classique'}</li>
                    <li>{eBikeEnabled ? '19 km/h' : '15 km/h'}</li>
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Typography alignSelf="flex-end" marginTop={2} variant="h5">
              Sources: Les Contributeurs OpenStreetMap ; Geovelo - {moment().format('MMMM YYYY')}
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
}

const StyledNorthIcon = styled(NorthIcon)`
  z-index: 2;
  position: absolute;
  left: 8px;
  bottom: 8px;
  && {
    height: 36px;
    width: 36px;
  }
`;

export default IsochronesTab;
