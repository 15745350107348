import {
  CyclabilityZone,
  Flow,
  OriginDestinationService,
  TDayPeriod,
  TTimePeriod,
  useCancellablePromise,
} from '@geovelo-frontends/commons';
import { ExpandMore, FilterList, InfoOutlined } from '@mui/icons-material';
import {
  Box,
  Checkbox,
  ListItemText,
  Menu,
  MenuItem,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../../../components/button';
import { IPeriodFormProps } from '../../../../components/form/period';
import Ranking from '../../../../components/ranking';
import { toOriginDestinationInput } from '../../../../models/origin-destination-form';

export const periodProps: {
  all: { timePeriod: TTimePeriod | 'peak'; dayPeriod: TDayPeriod }[];
  work: { timePeriod: TTimePeriod | 'peak'; dayPeriod: TDayPeriod }[];
  leisure: { timePeriod: TTimePeriod | 'peak'; dayPeriod: TDayPeriod }[];
} = {
  all: [{ timePeriod: 'all_day', dayPeriod: 'all' }],
  work: [{ timePeriod: 'peak', dayPeriod: 'week' }],
  leisure: [
    { timePeriod: 'off_peak', dayPeriod: 'week' },
    { timePeriod: 'all_day', dayPeriod: 'weekend' },
  ],
};

const types = ['internal', 'external'] as const;

export type TType = (typeof types)[number];

function MainFlows({
  period,
  zones,
  externalZones,
  flows: _flows,
}: {
  externalZones: CyclabilityZone[] | undefined;
  flows: Flow[] | undefined;
  period: IPeriodFormProps;
  zones: CyclabilityZone[] | undefined;
}): JSX.Element {
  const [rankingType, setRankingType] = useState<'all' | 'work' | 'leisure'>('all');
  const [selectedTypes, selectTypes] = useState<{ [key in TType]: boolean }>({
    internal: true,
    external: true,
  });
  const [typesMenuAnchorEl, setTypesMenuAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [flows, setFlows] = useState<Flow[]>();
  const [pageSize, setPageSize] = useState(5);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { cancellablePromise, cancelPromises } = useCancellablePromise();

  useEffect(() => {
    if (zones) getFlows();

    return () => {
      cancelPromises();
      setFlows(undefined);
      setPageSize(5);
    };
  }, [zones, rankingType]);

  useEffect(() => {
    setPageSize(5);
  }, [selectedTypes]);

  useEffect(() => {
    if (rankingType === 'all' && _flows) setFlows(_flows);
  }, [_flows]);

  async function getFlows() {
    if (!zones) return;

    if (rankingType === 'all') {
      setFlows(_flows);

      return;
    }

    const {
      values: { current: currentPeriod },
    } = period;

    try {
      const zoneIds = zones.map(({ id }) => id);
      let flows: Flow[];

      if (rankingType === 'leisure') {
        const [outgoingFlows, weekEndOutgoingFlows] = await cancellablePromise(
          Promise.all([
            OriginDestinationService.getFlows({
              period: currentPeriod.toIPeriod(),
              departureCyclabilityZoneIds: zoneIds,
              ...toOriginDestinationInput(periodProps[rankingType][0]),
            }),
            OriginDestinationService.getFlows({
              period: currentPeriod.toIPeriod(),
              departureCyclabilityZoneIds: zoneIds,
              ...toOriginDestinationInput(periodProps[rankingType][1]),
            }),
          ]),
        );

        weekEndOutgoingFlows.forEach((weekEndFlow) => {
          const flow = outgoingFlows.find(
            ({ origin, destination }) =>
              origin === weekEndFlow.origin && destination === weekEndFlow.destination,
          );
          if (flow) flow.count += weekEndFlow.count;
          else outgoingFlows.push(weekEndFlow);
        });

        const outgoingFlowsMap = outgoingFlows.reduce<{
          [key: number]: { [key: number]: true };
        }>((res, { origin, destination }) => {
          if (res[origin]) res[origin][destination] = true;
          else res[origin] = { [destination]: true };

          return res;
        }, {});

        const [incomingFlows, weekEndIncomingFlows] = await cancellablePromise(
          Promise.all([
            OriginDestinationService.getFlows({
              startId: outgoingFlows.length,
              period: currentPeriod.toIPeriod(),
              arrivalCyclabilityZoneIds: zoneIds,
              ...toOriginDestinationInput(periodProps[rankingType][0]),
            }),
            OriginDestinationService.getFlows({
              startId: outgoingFlows.length,
              period: currentPeriod.toIPeriod(),
              arrivalCyclabilityZoneIds: zoneIds,
              ...toOriginDestinationInput(periodProps[rankingType][1]),
            }),
          ]),
        );

        weekEndIncomingFlows.forEach((weekEndFlow) => {
          const flow = incomingFlows.find(
            ({ origin, destination }) =>
              origin === weekEndFlow.origin && destination === weekEndFlow.destination,
          );
          if (flow) flow.count += weekEndFlow.count;
          else incomingFlows.push(weekEndFlow);
        });

        flows = [
          ...outgoingFlows,
          ...incomingFlows.filter(
            ({ origin, destination }) => !outgoingFlowsMap[origin]?.[destination],
          ),
        ];
      } else {
        const outgoingFlows = await cancellablePromise(
          OriginDestinationService.getFlows({
            period: currentPeriod.toIPeriod(),
            departureCyclabilityZoneIds: zoneIds,
            ...toOriginDestinationInput(periodProps[rankingType][0]),
          }),
        );

        const outgoingFlowsMap = outgoingFlows.reduce<{ [key: number]: { [key: number]: true } }>(
          (res, { origin, destination }) => {
            if (res[origin]) res[origin][destination] = true;
            else res[origin] = { [destination]: true };

            return res;
          },
          {},
        );

        const incomingFlows = await cancellablePromise(
          OriginDestinationService.getFlows({
            startId: outgoingFlows.length,
            period: currentPeriod.toIPeriod(),
            arrivalCyclabilityZoneIds: zoneIds,
            ...toOriginDestinationInput(periodProps[rankingType][0]),
          }),
        );

        flows = [
          ...outgoingFlows,
          ...incomingFlows.filter(
            ({ origin, destination }) => !outgoingFlowsMap[origin]?.[destination],
          ),
        ];
      }

      setFlows(flows);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(t('cycling-insights.usage.origin_destination.server_error_zones'), {
          variant: 'error',
        });

        setFlows([]);
      }
    }
  }

  const externalZonesMap =
    externalZones?.reduce<{ [key: number]: boolean }>((res, zone) => {
      res[zone.id] = true;

      return res;
    }, {}) || {};

  const zonesMap =
    zones && externalZones
      ? [...zones, ...externalZones].reduce<{ [key: number]: CyclabilityZone }>((res, zone) => {
          res[zone.id] = zone;

          return res;
        }, {})
      : undefined;

  const filteredFlows =
    zonesMap &&
    flows?.filter(({ origin, destination }) => {
      if (!zonesMap[origin] || !zonesMap[destination]) return false;
      if (!selectedTypes.internal && !externalZonesMap[origin] && !externalZonesMap[destination])
        return false;
      if (!selectedTypes.external && (externalZonesMap[origin] || externalZonesMap[destination]))
        return false;

      return true;
    });

  const flowsCount = filteredFlows?.reduce((res, { count }) => (res += count), 0);

  return (
    <Ranking
      disableProgression
      hasPercentageBar
      data={
        zonesMap &&
        filteredFlows
          ?.sort((a, b) => b.count - a.count)
          .slice(0, pageSize)
          .map(({ id, count, origin, destination }) => {
            const originZone = zonesMap[origin];
            const destinationZone = zonesMap[destination];

            return {
              id: id,
              title: (
                <>
                  <Typography
                    fontSize="1rem"
                    fontWeight={600}
                    maxWidth="calc(50% - 18px)"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    title={originZone.name}
                    whiteSpace="nowrap"
                  >
                    {originZone.name}
                  </Typography>
                  <Typography fontSize="1rem" fontWeight={600}>
                    &nbsp;{'>'}&nbsp;
                  </Typography>
                  <Typography
                    fontSize="1rem"
                    fontWeight={600}
                    maxWidth="calc(50% - 18px)"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    title={destinationZone.name}
                    whiteSpace="nowrap"
                  >
                    {destinationZone.name}
                  </Typography>
                </>
              ),
              value: count,
            };
          })
      }
      loadingRowsCount={5}
      subtitle={
        <Box
          alignItems="center"
          display="flex"
          gap={2}
          justifyContent="space-between"
          marginTop={2}
        >
          <ToggleButtonGroup
            exclusive
            color="primary"
            onChange={(_, value) => value && setRankingType(value)}
            size="small"
            value={rankingType}
          >
            <ToggleButton value="all">
              <Typography fontSize="0.875rem" textTransform="none">
                {t('cycling-insights.usage.origin_destination.ranking.trips_types.all')}
              </Typography>
            </ToggleButton>
            <ToggleButton value="work">
              <Typography fontSize="0.875rem" marginRight={1} textTransform="none">
                {t('cycling-insights.usage.origin_destination.ranking.trips_types.work')}
              </Typography>
              <Tooltip
                title={t(
                  'cycling-insights.usage.origin_destination.ranking.trips_types.work_tooltip',
                )}
              >
                <InfoOutlined fontSize="small" />
              </Tooltip>
            </ToggleButton>
            <ToggleButton value="leisure">
              <Typography fontSize="0.875rem" marginRight={1} textTransform="none">
                {t('cycling-insights.usage.origin_destination.ranking.trips_types.leisure')}
              </Typography>
              <Tooltip
                title={t(
                  'cycling-insights.usage.origin_destination.ranking.trips_types.leisure_tooltip',
                )}
              >
                <InfoOutlined fontSize="small" />
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
          <Box>
            <Button
              color="inherit"
              endIcon={<FilterList />}
              onClick={({ currentTarget }) => setTypesMenuAnchorEl(currentTarget)}
              sx={{ border: '1px solid #C7CEDC', textTransform: 'initial' }}
              variant="outlined"
            >
              {t('commons.stats.type_label')}
            </Button>
            <Menu
              keepMounted
              anchorEl={typesMenuAnchorEl}
              id="types-menu"
              MenuListProps={{ style: { width: 250 } }}
              onClose={() => setTypesMenuAnchorEl(null)}
              open={Boolean(typesMenuAnchorEl)}
            >
              {types.map((type) => {
                const checked = selectedTypes[type];

                return (
                  <MenuItem
                    dense
                    key={type}
                    onClick={() => selectTypes({ ...selectedTypes, [type]: !checked })}
                    value={type}
                  >
                    <Checkbox checked={checked} style={{ padding: 4, marginRight: 8 }} />
                    <ListItemText
                      primary={t(`cycling-insights.usage.origin_destination.${type}_other`)}
                    />
                  </MenuItem>
                );
              })}
            </Menu>
          </Box>
        </Box>
      }
      title="cycling-insights.usage.origin_destination.ranking.title"
      total={flowsCount}
    >
      {filteredFlows && filteredFlows.length > pageSize && (
        <Box display="flex" justifyContent="flex-end" paddingBottom={3} paddingX={3}>
          <Button
            endIcon={<ExpandMore />}
            onClick={() => setPageSize(pageSize + 5)}
            size="small"
            variant="text"
          >
            {t('commons.actions.see_more')}
          </Button>
        </Box>
      )}
    </Ranking>
  );
}

export default MainFlows;
