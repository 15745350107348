import { PartnerService, PartnerUser } from '@geovelo-frontends/commons';
import { Add, Delete } from '@mui/icons-material';
import { Box, FormControl, MenuItem, Select, Typography } from '@mui/material';
import { Moment } from 'moment';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { AppContext } from '../../../app/context';
import Button from '../../../components/button';
import ConfirmDialog from '../../../components/confirm-dialog';
import Table, { TRow } from '../../../components/table';
import usePaginatedTable from '../../../hooks/table/paginated';
import useSelectableTable from '../../../hooks/table/selectable';
import useSortableTable from '../../../hooks/table/sortable';

import UsersFormDialog from './users-form-dialog';

type TKey = 'email' | 'username' | 'lastActivity' | 'actions';

const keys: TKey[] = ['email', 'username', 'lastActivity', 'actions'];

function ManageUsersTable(): JSX.Element {
  const [users, setUsers] = useState<PartnerUser[]>();
  const [count, setCount] = useState<number>();
  const [rows, setRows] = useState<TRow<number, TKey>[] | undefined>();
  const [formDialogOpen, openFormDialog] = useState(false);
  const [removeDialogOpen, openRemoveDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    partner: { current: currentPartner },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { page, rowsPerPage, setPage, onPageChange, onRowsPerPageChange } = usePaginatedTable<
    number,
    TKey
  >(25);
  const { orderBy, order, onSortRequest } = useSortableTable<number, TKey>('username', 'asc', {
    setPage,
  });
  const { selectedKeys, onToggleKey, onSelectAllKeys, onUnselectAllKeys } = useSelectableTable();

  useEffect(() => {
    if (!users) {
      setRows(undefined);

      return;
    }

    const _rows: TRow<number, TKey>[] = users.map((user) => ({
      key: user.id,
      cells: {
        email: { value: user.email },
        username: { value: user.username || '' },
        lastActivity: { value: user.last_activity, format: (value: Moment) => value.format('LL') },
        actions: {
          value: null,
          format: () => {
            return (
              <FormControl fullWidth size="small" variant="standard">
                <Select
                  disableUnderline
                  onChange={({ target: { value } }) => handleEdit(user, value as 'user' | 'admin')}
                  value={user.isAdmin ? 'admin' : 'user'}
                >
                  <MenuItem value="user">
                    <Trans i18nKey="cycling-insights.admin.manage_partner.roles.user" />
                  </MenuItem>
                  <MenuItem value="admin">
                    <Trans i18nKey="cycling-insights.admin.manage_partner.roles.admin" />
                  </MenuItem>
                </Select>
              </FormControl>
            );
          },
        },
      },
    }));

    setRows(_rows);
  }, [users]);

  useEffect(() => {
    update();
  }, [orderBy, order, page, rowsPerPage]);

  async function update() {
    setUsers(undefined);
    setCount(undefined);

    if (!currentPartner) return;

    try {
      const { count, users: _users } = await PartnerService.getPartnerUsers(currentPartner.id, {
        page: page + 1,
        rowsPerPage,
      });

      setUsers(_users);
      setCount(count);
    } catch {
      enqueueSnackbar(t('cycling-insights.admin.manage_partner.members_list.error'), {
        variant: 'error',
      });
    }
  }

  async function handleFormDialogClose(userId?: number) {
    if (userId !== undefined) update();
    openFormDialog(false);
  }

  async function handleRemove() {
    setLoading(true);

    if (currentPartner?.id) {
      try {
        const requests = selectedKeys.map((key) =>
          PartnerService.removePartnerUser(currentPartner, key as number),
        );
        await Promise.all(requests);

        openRemoveDialog(false);
        onUnselectAllKeys();

        if (page === 0) update();
        else setPage(0);
      } catch {
        enqueueSnackbar(t('cycling-insights.admin.manage_partner.remove_dialog.not_removed'), {
          variant: 'error',
        });
      }
    }

    setLoading(false);
  }

  async function handleEdit(user: PartnerUser, role: 'admin' | 'user') {
    if (!currentPartner) return;

    try {
      await PartnerService.updatePartnerUser(currentPartner, user.id, role === 'admin');
      users?.splice(
        users.findIndex(({ id }) => id === user.id),
        1,
        new PartnerUser(
          user.id,
          user.created,
          user.email,
          user.username,
          user.last_activity,
          role === 'admin',
          user.permissions,
        ),
      );
      setUsers([...(users || [])]);

      enqueueSnackbar(t('cycling-insights.admin.manage_partner.form_dialog.updated'));
    } catch (err) {
      enqueueSnackbar(t('cycling-insights.admin.manage_partner.form_dialog.not_updated'), {
        variant: 'error',
      });
    }
  }

  return (
    <>
      <Box
        bgcolor="white"
        border="1px solid #CFCFCF"
        borderRadius={4}
        display="flex"
        flexDirection="column"
        flexGrow={1}
        gap={4}
        padding={4}
        sx={{ overflowY: 'auto' }}
      >
        <Box
          alignItems="center"
          display="flex"
          flexDirection="row"
          gap={2}
          justifyContent="space-between"
        >
          <Typography
            color="primary"
            component="h2"
            flexDirection="column"
            fontWeight="700"
            lineHeight="2rem"
            variant="h5"
          >
            <Trans i18nKey="cycling-insights.admin.manage_partner.members_list.title" />
          </Typography>
          <Box display="flex" gap={2}>
            <Button
              color="error"
              disabled={selectedKeys.length === 0}
              onClick={() => openRemoveDialog(true)}
              startIcon={<Delete />}
              variant="contained"
            >
              <Trans i18nKey="commons.actions.remove" />
            </Button>
            <Button
              color="primary"
              onClick={() => openFormDialog(true)}
              startIcon={<Add />}
              variant="contained"
            >
              <Trans i18nKey="cycling-insights.admin.manage_partner.members_list.add" />
            </Button>
          </Box>
        </Box>
        <Table
          hasBorder
          selectable
          count={count}
          headers={{
            email: {
              label: <Trans i18nKey="commons.email" />,
              // sortable: true,
            },
            username: {
              label: <Trans i18nKey="commons.user.username" />,
              // sortable: true,
            },
            lastActivity: { label: <Trans i18nKey="commons.user.last_activity" /> },
            actions: {
              label: <Trans i18nKey="cycling-insights.admin.manage_partner.members_list.role" />,
              width: 140,
            },
          }}
          keys={keys}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          onSelectAllKeys={() => onSelectAllKeys(rows || [])}
          onSortRequest={onSortRequest}
          onToggleKey={onToggleKey}
          onUnselectAllKeys={onUnselectAllKeys}
          order={order}
          orderBy={orderBy}
          page={page}
          rows={rows}
          rowsPerPage={rowsPerPage}
          selectedKeys={selectedKeys}
          size="small"
          title="Partner users table"
        />
      </Box>
      <UsersFormDialog onClose={handleFormDialogClose} open={formDialogOpen} user={null} />
      <ConfirmDialog
        dialogTitle="partner-user-remove-dialog"
        loading={loading}
        onCancel={() => {
          openRemoveDialog(false);
        }}
        onConfirm={handleRemove}
        open={removeDialogOpen}
        title={
          <Trans
            count={selectedKeys.length}
            i18nKey="cycling-insights.admin.manage_partner.remove_dialog.title"
            values={{ count: selectedKeys.length }}
          />
        }
      />
    </>
  );
}

export default ManageUsersTable;
